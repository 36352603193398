import React from "react";

type Props = { width: number | string };

export default function IconRecipeTime(props: Props) {
  return (
    <svg
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 922.76022 800"
    >
      <path
        style={cls_1}
        d="M55.06891,733.68389c47.19136,57.01459,185.04057,86.52793,314.41245-18.66233C485.10514,621.00967,534.3376,370.53094,534.3376,370.53094l-33.20136-40.11248S204.23181,341.39893,110.947,418.61139C-19.01721,526.18365,7.87755,676.6693,55.06891,733.68389Z"
      />
      <path d="M186.74429,793.56281c-60.84194,0-111.276-22.98211-137.69433-54.89619h0c-47.7796-57.726-80.13227-212.636,56.91752-326.0744,94.24343-78.00415,382.64867-89.52572,394.87961-89.97956a7.6801,7.6801,0,0,1,6.30807,2.826l33.19934,40.11a7.81932,7.81932,0,0,1,1.64757,6.48732A1069.20525,1069.20525,0,0,1,502.6167,510.16512C466.6142,610.22081,423.476,681.18465,374.40736,721.08107,310.20543,773.28471,243.883,793.56281,186.74429,793.56281Zm-125.6579-64.8579c45.9032,55.45682,180.38247,80.3344,303.466-19.74417,104.69331-85.12457,154.7879-305.46449,161.37056-336.345l-28.32528-34.22145c-34.90412,1.64376-297.1389,16.266-381.66852,86.2344C54.48085,475.48982-27.60792,621.54787,61.08639,728.70491Z" />
      <path
        style={cls_1}
        d="M526.69459,358.65019S358.44316,795.09068,44.8425,720.2376c0,0,311.36806-124.89555,472.78517-371.35178"
      />
      <path d="M115.52111,736.53469a311.517,311.517,0,0,1-72.48936-8.69933,7.81212,7.81212,0,0,1-1.09839-14.84722c3.093-1.2395,310.99835-126.89358,469.16148-368.38116l13.06616,8.55823q-1.17847,1.802-2.36838,3.59643l12.189,4.69863c-.42715,1.1022-43.44709,111.4209-124.55952,210.98842C321.16255,680.79183,220.72547,736.53469,115.52111,736.53469ZM70.73156,717.40454c260.44228,40.82319,415.2378-283.92018,443.84911-350.00233C377.61478,565.519,144.39558,683.656,70.73156,717.40454Z" />
      <path
        style={cls_1}
        d="M505.77092,337.3778S50.61386,410.97326,44.8425,720.2376c0,0,187.32-273.23405,465.62642-374.14161"
      />
      <path d="M44.84331,728.04893a7.81646,7.81646,0,0,1-7.81071-7.95564c2.418-129.548,84.35418-238.21531,236.95675-314.25154,114.25075-56.929,229.38631-75.99046,230.53427-76.17733l1.57129,9.71c.57589-.20977,1.13652-.41571,1.71241-.62166l5.3241,14.68323C240.48108,452.298,53.146,721.943,51.28486,724.65463A7.80734,7.80734,0,0,1,44.84331,728.04893Zm429.574-376.20331C374.72009,374.98791,79.33554,462.7936,54.294,694.26988,102.98889,630.60571,259.85531,442.32862,474.41728,351.84562Z" />
      <path
        style={cls_2}
        d="M836.42934,20.87462C892.24666,4.193,925.18686,42.45306,899.43775,96.9987S538.153,385.89736,538.153,385.89736l-53.04784-64.09013S782.94924,36.85777,836.42934,20.87462Z"
      />
      <path d="M536.72755,396.42542,474.45161,321.1901l5.25164-5.02662c12.21949-11.68556,299.83527-286.43732,354.4912-302.77193h0c29.53426-8.83663,55.04493-3.95112,69.94936,13.38653,15.21335,17.69615,16.07146,44.50733,2.35694,73.55342-26.33064,55.78482-350.1053,282.35271-363.87321,291.97118Zm-40.9681-73.99583,43.817,52.9397c96.92454-67.95854,332.82486-239.39,352.79787-281.70435,10.96474-23.2262,10.93423-43.89331-.07628-56.70395-10.70159-12.44832-30.23982-15.5871-53.63382-8.604h0C797.14314,40.76719,582.05868,240.11962,495.75945,322.42959Z" />
    </svg>
  );
}

const cls_1 = { fill: "#F2EAFF" };
const cls_2 = { fill: "#F2EAFF" };
