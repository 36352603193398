import React from "react";
import styles from "./ImageBlock.module.scss";

export interface ImageBlockProps {
  src: string;
  width: number;
  height: number;
  versions: Map<String, VersionsData>;
  alt: string;
}

export interface VersionsData {
  src: string;
  width: number;
  height: number;
}

const ImageBlock = (props: ImageBlockProps) => {
  const version = props?.versions;
  return (
    <>
      {version && (
        <p className={styles.container}>
          <img
            src={version?.w600.src}
            className={styles.img}
            width={version?.w600.width}
            height={version?.w600.height}
            alt={props.alt}
          ></img>
        </p>
      )}
    </>
  );
};

export default ImageBlock;
