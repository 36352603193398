import React from "react";
import styles from "./styles.module.scss";

const RecipeInstructionsListItem = (props) => {
  const { children, data, keys } = props;
  return (
    <ul className={styles.list}>
      {children.map((child, i) => {
        return (
          <li key={keys[i]} className={styles.item}>
            <p>{child}</p>
            <span>{data[i].quantity}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default RecipeInstructionsListItem;
