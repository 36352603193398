import React, { useEffect, useCallback } from "react";
import styles from "./AdsenseCard.module.scss";

declare global {
  var adsbygoogle: unknown[];
}

export type AdsenseCardProps = {
  slot: number;
  format?: "auto" | "rectangle" | "vertical" | "horizontal" | "fluid";
  responsive?: boolean;
  style?: React.CSSProperties;
};

const defaultStyle = {
  display: "inline-block",
  width: "336px",
  height: "280px",
};
export const defaultAdsenseCardProps = {
  format: "auto",
  responsive: true,
  style: defaultStyle,
};

export function AdsenseCard(props: AdsenseCardProps) {
  const ad_client = "ca-pub-5090909614472431";

  const insProps = { ...defaultAdsenseCardProps, ...props };

  const style = { ...defaultStyle, ...props.style };
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <div className={styles.container}>
      <ins
        className={`adsbygoogle`}
        style={style}
        data-ad-client={ad_client}
        data-ad-slot={insProps.slot}
        // data-ad-format={insProps.format}
        // data-full-width-responsive={insProps.responsive}
      ></ins>
      {/* <script
        dangerouslySetInnerHTML={{
          __html: `(adsbygoogle = window.adsbygoogle || []).push({});`,
        }}
      /> */}
    </div>
  );
}

export default AdsenseCard;
