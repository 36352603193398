import React from "react";
import styles from "./styles.module.scss";

const BackgrountBlock = (props) => {
  const { children, keys } = props;

  return (
    <div className={styles.wrapper}>
      {children.map((child, i) => {
        return child[1].length ? (
          <p key={keys[i]}>{child}</p>
        ) : (
          <br key={keys[i]} />
        );
      })}
    </div>
  );
};

export default BackgrountBlock;
