import React from "react";
import styles from "./styles.module.scss";

const ButtonLink = (props) => {
  const { data, keys } = props;
  return (
    <div className={styles.container}>
      <a
        href={data.url}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    </div>
  );
};

export default ButtonLink;
