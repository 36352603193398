import React from "react";
import { Person } from "schema-dts";
import { JsonLd } from "react-schemaorg";
import { RawDraftContentBlock, RawDraftEntity } from "draft-js";

import ArticleSchema from "./ArticleSchema";
import RecipeSchema from "./RecipeSchema";
import RecipeCollectionsSchema from "./RecipeCollectionsSchema";

import { ArticleBodyRenderProps } from "../..";

export interface SchemaOrgProps {
  raw: Raw;
  article: ArticleBodyRenderProps;
}

interface Raw {
  blocks: Array<RawDraftContentBlock>;
  entityMap: { [key: string]: RawDraftEntity };
}

const SchemaOrg = (props: SchemaOrgProps) => {
  const { article } = props;
  const renderSwitch = () => {
    switch (article.category_id) {
      case 4:
        return <RecipeSchema {...props} />;
      case 8:
        return <RecipeSchema {...props} />;
      case 7:
        return <RecipeCollectionsSchema {...props} />;
      default:
        return <ArticleSchema {...props} />;
    }
  };
  return <>{renderSwitch()}</>;
};

export default SchemaOrg;
