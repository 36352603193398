import React from "react";
import styles from "./styles.module.scss";
import IconRecipeYield from "../icons/IconRecipeYield";
import IconRecipeTime from "../icons/IconRecipeTime";

export interface RecipeDetailsProps {
  yield: string;
  time: string;
}
const RecipeDetails = (props: RecipeDetailsProps) => {
  return (
    <div className={styles.details}>
      <div className={styles.details_item}>
        <IconRecipeYield width={"3.3rem"} />
        <span>{props.yield}</span>
      </div>
      <div className={styles.details_item}>
        <IconRecipeTime width={"3.1rem"} />
        <span>{props.time}</span>
      </div>
    </div>
  );
};

export default RecipeDetails;
