import React from "react";
import { Recipe, Person } from "schema-dts";
import { JsonLd } from "react-schemaorg";
import { SchemaOrgProps } from ".";
import ArticleSchema from "./ArticleSchema";

const RecipeSchema = (props: SchemaOrgProps) => {
  const { raw, article } = props;

  const recipeDetails = getBlocksByTypename(["recipe-details"], raw);
  const recipeInstructions = getBlocksByTypename(
    ["recipe-instructions-list-item"],
    raw
  ).map((v) => {
    return { "@type": "HowToStep", text: v.text };
  });
  const ingredients = getBlocksByTypename(["ingredients-list-item"], raw).map(
    (v) => {
      return `${v.text} ${v?.data?.quantity}`;
    }
  );

  const author: Person = {
    "@type": "Person",
    name: "Fily",
    url: "https://fily.jp/",
  };

  return (
    <>
      <JsonLd<Recipe>
        item={{
          "@context": "https://schema.org",
          "@type": "Recipe",
          author: author,
          name: article.title,
          description: article.description,
          dateModified: article.modified_at,
          datePublished: article.published_at,

          image: [
            article.main_image?.url,
            article.image_4x3?.url,
            article.image_16x9?.url,
          ],
          recipeIngredient: ingredients,
          recipeInstructions: recipeInstructions,
          recipeYield: recipeDetails[0]?.data.yield,
        }}
      />
    </>
  );
};

export default RecipeSchema;

const getBlocksByTypename = (type_names: Array<string>, raw) => {
  if (!raw || !Array.isArray(raw.blocks)) {
    // alert("invalid raw object");
    return null;
  }
  if (!raw.blocks.length) {
    return null;
  }

  const filtered_blocks = raw.blocks.filter((b) => type_names.includes(b.type));

  return filtered_blocks;
};
