// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// require('react-server')
// import "packs/application.scss";
const images = require.context('../images/', true)
Rails.start()
ActiveStorage.start()
// Support component names relative to this directory:
var componentRequireContext = require.context("components/application", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// import '../src/js/drawer/index'
import '../src/js/sidebar_fixed.ts'

import '@fortawesome/fontawesome-free/js/fontawesome';
// import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/solid';


