import React, { useEffect, useCallback } from "react";
// import styles from "./AdsenseCard.module.scss";

export type AdxSlotProps = {
  id: string;
  style?: React.CSSProperties;
};

export function AdxSlot(props: AdxSlotProps) {
  const style = {
    ...{ minWidth: "200px", minHeight: "50px" },
    ...props.style,
  };
  return (
    <div id={props.id} style={style}>
      <script
        dangerouslySetInnerHTML={{
          __html: `googletag.cmd.push(function() { googletag.display('${props.id}'); });`,
        }}
      />
    </div>
  );
}

export default AdxSlot;
