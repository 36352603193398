import React, { useEffect, useCallback } from "react";
import AdsenseCard, {
  AdsenseCardProps,
  defaultAdsenseCardProps,
} from "./AdsenseCard";

import AdxArea from "./AdxArea";
import styles from "./AdsUnit.module.scss";

const AdsUnit = ({ header_number }) => {
  const getAdDiv = () => {
    switch (header_number) {
      case 1:
        return (
          <div>
            <AdxArea
              className={styles.ads_unit_s}
              slot_ids={["div-gpt-ad-1694670452806-0"]}
            />
            <AdxArea
              className={styles.ads_unit_d}
              slot_ids={[
                "div-gpt-ad-1694675145602-0",
                "div-gpt-ad-1694675193742-0",
              ]}
              slot_style={{ minHeight: "200px" }}
            />
          </div>
        );

      case 2:
        return (
          <div>
            <AdxArea
              className={styles.ads_unit_s}
              slot_ids={["div-gpt-ad-1694670478769-0"]}
            />
            <AdxArea
              className={styles.ads_unit_d}
              slot_ids={[
                "div-gpt-ad-1694675218388-0",
                "div-gpt-ad-1694675246425-0",
              ]}
              slot_style={{ minHeight: "200px" }}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <AdxArea
              className={styles.ads_unit_s}
              slot_ids={["div-gpt-ad-1694670521233-0"]}
            />

            <AdxArea
              className={styles.ads_unit_d}
              slot_ids={[
                "div-gpt-ad-1694675428147-0",
                "div-gpt-ad-1694675457327-0",
              ]}
              slot_style={{ minHeight: "200px" }}
            />
          </div>
        );
      case 4:
        return (
          <div>
            <AdxArea
              className={styles.ads_unit_s}
              slot_ids={["div-gpt-ad-1694670547930-0"]}
            />

            <AdxArea
              className={styles.ads_unit_d}
              slot_ids={[
                "div-gpt-ad-1694675490494-0",
                "div-gpt-ad-1694675530161-0",
              ]}
              slot_style={{ minHeight: "200px" }}
            />
          </div>
        );
      case 5:
        return AdsenseCard({ slot: 1168786758 });
      case 6:
        return AdsenseCard({ slot: 6229541743 });
    }
  };
  return <>{getAdDiv()}</>;
};
export default AdsUnit;
