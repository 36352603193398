import React from "react";
import styles from "./styles.module.scss";
import Card from "./Card";
const InternalLink = (props) => {
  const { data, keys } = props;

  return (
    <div className={"ly_grid ly_grid__gap3"}>
      {data.map((item, i) => (
        <article className="ly_grid_col hp_fx6">
          <Card key={keys[i]} {...item} />
        </article>
      ))}
    </div>
  );
};

export default InternalLink;
