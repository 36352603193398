import React from "react";
import { Article, Person, Organization } from "schema-dts";
import { JsonLd } from "react-schemaorg";
import { SchemaOrgProps } from ".";

const ArticleSchema = (props: SchemaOrgProps) => {
  const { raw, article } = props;
  const author: Person = {
    "@type": "Person",
    name: "Fily",
    url: "https://fily.jp/",
  };

  return (
    <JsonLd<Article>
      item={{
        "@context": "https://schema.org",
        "@type": "Article",
        author: author,
        dateModified: article.modified_at,
        datePublished: article.published_at,
        headline: article.title,
        description: article.description,
        image: [
          article.main_image?.url,
          article.image_4x3?.url,
          article.image_16x9?.url,
        ],
      }}
    />
  );
};

export default ArticleSchema;
