import React, { useEffect, useCallback } from "react";
// import styles from "./AdsenseCard.module.scss";
import AdxSlot from "./AdxSlot";

export type AdxAreaProps = {
  slot_ids: string[];
  className?: string;
  style?: React.CSSProperties;
  slot_style?: React.CSSProperties;
};

export function AdxArea(props: AdxAreaProps) {
  const style = {
    ...{
      display: "flex",
      minHeight: "50px",
      margin: "0 auto",
      gap: "8px",
      justifyContent: "center",
    },
    ...props.style,
  };
  const slot_style = {
    ...{ minWidth: "200px", minHeight: "50px" },
    ...props.slot_style,
  };

  return (
    <div style={style} className={props.className}>
      {props.slot_ids.map((slot_id) => (
        <AdxSlot id={slot_id} style={slot_style} />
      ))}
    </div>
  );
}

export default AdxArea;
