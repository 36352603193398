import React from "react";
// import s from "Card.module.scss";
const Card = (props) => {
  const {
    url,
    title,
    description,
    main_image_versions,
    main_image_width,
    main_image_height,
  } = props;
  return (
    <a href={url}>
      <div className="bl_card">
        <figure className="bl_card_imgWrapper">
          <img
            src={main_image_versions?.w600.url}
            alt={"Cover image for " + title}
            width={main_image_width}
            height={main_image_height}
          />
        </figure>
        <div className="bl_card_body">
          <h2 className="bl_card_ttl">{title}</h2>
          {/* <p className="bl_card_txt">{description}</p> */}
        </div>
      </div>
    </a>
  );
};

export default Card;
