import React from "react";
import s from "./styles.module.scss";

const IframelyHtml = ({ html }: { html: string }) => {
  return (
    <div className={s.container}>
      <div
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  );
};

export default IframelyHtml;
