
window.addEventListener('DOMContentLoaded', () => {
  fix_element();
});

function fix_element() {
  window.addEventListener("scroll", function () {
    const fix = document.querySelector<HTMLElement>(".js_fiexd");
    if (!fix) return;
    const prevSibling = fix?.previousElementSibling;
    if(!prevSibling) return;
    const prevSiblingBottom = prevSibling?.offsetTop + prevSibling?.offsetHeight;
   
    if (window.scrollY >= prevSiblingBottom) {
      (fix as HTMLElement).style.position = "fixed";
      (fix as HTMLElement).style.top = "16px";
    } else {
      (fix as HTMLElement).style.position = "";
      (fix as HTMLElement).style.top = "";
    }
  });
}