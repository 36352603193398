import React from "react";
import ArticleBlockRender from "../../src/js/ArticleBodyRender";

interface Props {
  body_value: string;
  category_id: number;
  title: string;
  description: string;
  published_at: any;
  modified_at: any;
  main_image: any;
  image_191x1: any;
  image_16x9: any;
  image_4x3: any;
  toc_level: number;
}

export default function (props: Props) {
  const html = ArticleBlockRender(props);
  return <>{html}</>;
}
