import React, { useEffect, useState } from "react";
import SchemaOrg from "../SchemaOrg";
import styles from "./styles.module.scss";
import { ContentBlock } from "draft-js";

const Toc = (props) => {
  const { raw } = props;
  const [headers, setHeaders] = useState(getHeaderBlocks(raw));
  useEffect(() => {
    const h = getHeaderBlocks(raw);
    setHeaders(h);
  }, [raw]);
  return (
    <>
      {headers && (
        <div>
          <nav className={styles.toc}>
            <h2 className={styles.toc_title}>目次</h2>
            <ol className={styles.toc_list}>
              {headers.map((header) => (
                <li key={header.key} className={styles.toc_item}>
                  <a href={`#${header.key}`} className={styles.toc_link}>
                    {header.text}
                  </a>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      )}
    </>
  );
};

const getHeaderBlocks = (raw: ContentBlock) => {
  if (!raw || !Array.isArray(raw.blocks)) {
    // alert("invalid raw object");
    return null;
  }
  if (!raw.blocks.length) {
    return null;
  }
  const { blocks } = raw;

  const headings = blocks.filter((b) => ["header-one"].includes(b.type));

  return headings;
};

export default Toc;
