import React from "react";
import styles from "./styles.module.scss";

const HtmlAmazon = (props) => {
  const { data } = props;
  return (
    <div
      className={styles.wrapper}
      dangerouslySetInnerHTML={{
        __html: data?.value,
      }}
    />
  );
};

export default HtmlAmazon;
