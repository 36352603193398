import "../../stylesheets/application/setting/_variables.scss";
import "../../stylesheets/application/setting/_colors.scss";

import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React from "react";
import redraft from "redraft";
import {
  ImageBlock,
  IngredientsList,
  RecipeDetails,
  InternalLink,
  HtmlAmazon,
  ButtonLink,
  BackgroundBlock,
  IframelyHtml,
} from "../blockComponents";

import Toc from "./components/Toc";
import SchemaOrg from "./components/SchemaOrg";

import styles from "./styles.module.scss";

import AdsUnit from "./components/ads/AdsUnit";
// React.lazy(() => import(`./components/ads/heading_${}.html`));
// import heading_2 from "./components/ads/heading_2.ts";
// declare module "./components/ads/*.html" {
//   const value: string;
//   export default value;
// }
var header_one_counter = 0;
const addBreaklines = (children) => children.map((child) => [child, <br />]);

const renderers = {
  blocks: {
    unstyled: (children, { keys }) => (
      <p key={keys[0]}>{addBreaklines(children)}</p>
    ),
    "header-one": (children, { keys }) => {
      header_one_counter++;

      return children.map((child, i) => (
        <>
          <AdsUnit header_number={header_one_counter} />

          <h2 key={keys[i]} id={keys[i]}>
            {child}
          </h2>
        </>
      ));
    },
    // "header-one": (children, { keys }) =>
    //   children.map((child, i) => (
    //     <h2 key={keys[i]} id={keys[i]}>
    //       {child}
    //     </h2>
    //   )),
    "header-two": (children, { keys }) =>
      children.map((child, i) => (
        <h3 key={keys[i]} id={keys[i]}>
          {child}
        </h3>
      )),
    "header-three": (children, { keys }) =>
      children.map((child, i) => (
        <h4 key={keys[i]} id={keys[i]}>
          {child}
        </h4>
      )),
    "header-four": (children, { keys }) =>
      children.map((child, i) => (
        <h5 key={keys[i]} id={keys[i]}>
          {child}
        </h5>
      )),
    "header-five": (children, { keys }) =>
      children.map((child, i) => (
        <h6 key={keys[i]} id={keys[i]}>
          {child}
        </h6>
      )),
    "html-amazon": (children, { keys, data }) =>
      children.map((child, i) => (
        <HtmlAmazon key={keys[i]} data={data[i]}></HtmlAmazon>
      )),

    // 'header-six': (children, { keys }) => children.map((child, i) => <h7 key={keys[i]}>{child}</h7>),
    "unordered-list-item": (children, { depth, keys }) => (
      <ul
        key={keys[keys.length - 1]}
        className={`${styles.ul} ul-level-${depth}`}
      >
        {children.map((child) => (
          <li>
            <p>{child}</p>
          </li>
        ))}
      </ul>
    ),
    "ordered-list-item": (children, { depth, keys }) => (
      <ol key={keys.join("|")} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ol>
    ),

    image: (children, { keys, data }) =>
      children.map((child, i) => <ImageBlock key={keys[i]} {...data[i]} />),

    "recipe-details": (children, { keys, data }) =>
      children.map((child, i) => <RecipeDetails key={keys[i]} {...data[i]} />),
    "ingredients-list-item": (children, { keys, data }) => (
      <IngredientsList key={keys[keys.length - 1]} data={data} keys={keys}>
        {children}
      </IngredientsList>
    ),
    "recipe-instructions-list-item": (children, { depth, keys }) => (
      <ol key={keys.join("|")} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>
            <p>{child}</p>
          </li>
        ))}
      </ol>
    ),
    "internal-link": (children, { keys, data }) => (
      <InternalLink key={keys[keys.length - 1]} data={data} keys={keys} />
    ),
    "button-link": (children, { keys, data }) =>
      children.map((child, i) => (
        <ButtonLink key={keys[i]} data={data[i]}>
          {child}
        </ButtonLink>
      )),
    "background-block": (children, { keys }) => (
      <BackgroundBlock keys={keys}>{children}</BackgroundBlock>
    ),
    "iframely-html": (children, { keys, data }) =>
      children.map((child, i) => <IframelyHtml key={keys[i]} {...data[i]} />),

    // children.map((child, i) => <InternalLink key={keys[i]} {...data[i]} />),
  },
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
    ITALIC: (children, { key }) => <i key={key}>{children}</i>,
    UNDERLINE: (children, { key }) => <u key={key}>{children}</u>,
    CODE: (children, { key }) => (
      <span key={key} style={styles.code}>
        {children}
      </span>
    ),
  },

  entities: {
    // key is the entity key value from raw
    LINK: (children, data, { key }) => (
      <a key={key} href={data.url}>
        {children}
      </a>
    ),
  },
};

export interface ArticleBodyRenderProps {
  body_value: string;
  category_id: number;
  title: string;
  description: string;
  published_at: any;
  modified_at: any;
  main_image: any;
  image_191x1: any;
  image_16x9: any;
  image_4x3: any;
  toc_level: number;

  is_smartphone: boolean;
}

export default function ArticleBodyRender(props: ArticleBodyRenderProps) {
  const { body_value, ...otherProps } = props;
  const { toc_level } = otherProps;

  const raw = JSON.parse(body_value);
  const rendered = redraft(raw, renderers);
  const className = styles.container;
  return rendered ? (
    <>
      <SchemaOrg raw={raw} article={props} />
      {toc_level > 0 && <Toc raw={raw} article={otherProps} />}
      <div className={className}>{rendered}</div>
    </>
  ) : (
    <div>Nothing to render.</div>
  );
}
